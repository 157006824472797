import React, { FC, useEffect, useMemo, useState } from 'react'
import { formatValue, stripFormatting, stripInvalidChars } from '../../utils'
import * as styles from './card-input-slider.styles'
import { css } from '@emotion/react'
import orangeBar from './../../assets/imgs/pattern-orangebar.svg'
import { InformationSection } from '../information-section'
import { PercentageToolTip } from '../percentage-tool-tip'
import { MultiThumbSlider } from '../multi-thumb-slider'

/*
In order to debounce without having the component value controlled by an async response
The 'localValue' will either be current or preceding the 'value' provided by the parent component
*/

export interface CardInputSliderProps {
  id?: string
  label: string
  min: number
  max: number
  onValueChange: (newValue: number) => void
  step: number
  symbol?: string
  symbolBefore?: boolean
  symbolInput?: boolean
  shadowedValue?: string
  value: number
  highlight?: boolean
  additionalInfo?: string
  toolTipPercent?: number
  disabled?: boolean
  disableMax?: number
  disableMin?: number
  multiSlider?: boolean
  subSliderDiff?: number
  lock?: boolean
  subSliderValue?: number
}
export const CardInputSlider: FC<CardInputSliderProps> = (props) => {
  const {
    id,
    label,
    min,
    max,
    onValueChange,
    step,
    symbol,
    symbolBefore,
    symbolInput,
    shadowedValue,
    value,
    highlight,
    additionalInfo,
    toolTipPercent,
    disabled,
    disableMin,
    multiSlider = false,
    subSliderDiff = 0,
    lock,
    subSliderValue,
  } = props

  const [lockSlider, setLockSlider] = useState(false)
  const [localValue, setLocalValue] = useState<number>(value)
  const [prevLocalValue, setPrevLocalValue] = useState<number>(value)
  const [textEntryValue, setTextEntryValue] = useState<string>(value.toString())
  const [prevTextEntryValue, setPrevTextEntryValue] = useState<string>(
    value.toString(),
  )
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null)
  const [prevValue, setPrevValue] = useState<number>(value)
  const [isEditingText, setIsEditingText] = useState<boolean>(false)

  useEffect(() => {
    setLockSlider(lock || min == max)
  }, [min, max, lock, label, subSliderDiff])

  const sliderPercentage = useMemo(() => {
    return Math.trunc((100 * (localValue - min)) / (max - min))
  }, [min, max, localValue])

  const partialSliderStyles = useMemo(() => {
    const sliderColor = highlight ? 'transparent' : 'var(--slider-grey)'
    const highlightBar = highlight ? `, url(${orangeBar})` : ''
    const backgroundColor = lockSlider
      ? 'var(--slider-grey)'
      : `linear-gradient(to right, var(--slider-blue) ${sliderPercentage}%, ${sliderColor} ${sliderPercentage}% 100%)${highlightBar}`

    const style = css({
      '::-webkit-slider-runnable-track': {
        height: '6px',
        border: 'solid 1px var(--slider-grey)',
        marginTop: '-1px',
        background: `${backgroundColor}`,
      },
      '::-webkit-slider-thumb': {
        label: 'wk-st',
        WebkitAppearance: 'none',
        marginTop: '-16px',
        opacity: lockSlider ? 0 : 1,
        ...styles.sliderThumbStyles,
      },
      '::-moz-range-track': {
        height: '6px',
        border: 'solid 1px var(--slider-grey)',
        marginTop: '-1px',
        background: `${backgroundColor}`,
      },
      '::-moz-range-thumb': {
        label: 'moz-rt',
        ...styles.sliderThumbStyles,
        opacity: lockSlider ? 0 : 1,
      },
      '::-ms-thumb': {
        label: 'ms-t',
        opacity: lockSlider ? 0 : 1,
        ...styles.sliderThumbStyles,
      },
    })
    return style
  }, [highlight, lockSlider, sliderPercentage])

  // update localValue from value prop change
  useEffect(() => {
    let updateValue = value
    if (updateValue < min) updateValue = min
    if (updateValue > max - subSliderDiff) updateValue = max - subSliderDiff
    if (updateValue < disableMin) updateValue = disableMin
    if (updateValue !== prevValue) {
      if (updateValue !== localValue) {
        setLocalValue(updateValue)
        setTextEntryValue(updateValue.toString())
        setPrevTextEntryValue(updateValue.toString())
      }
      setPrevValue(updateValue)
    }
  }, [disableMin, localValue, max, min, prevValue, subSliderDiff, value])

  // use timer to onValueChange / send updated localValue to parent
  useEffect(() => {
    if (localValue !== prevLocalValue) {
      setPrevLocalValue(localValue)
      if (timeoutId) clearTimeout(timeoutId)
      const tid = setTimeout(
        () => {
          onValueChange(localValue)
        },
        isEditingText ? 250 : 20,
      )
      setTimeoutId(tid)
    }
  }, [isEditingText, localValue, onValueChange, prevLocalValue, timeoutId])

  const handleOnFocus = () => {
    setIsEditingText(true)
  }

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputTextValue = Number(event.target.value)

    // if isNaN, then use prevTextEntryValue
    if (isNaN(inputTextValue)) {
      inputTextValue = Number(prevTextEntryValue)
    }

    let updateValue = inputTextValue

    // ensure rounded to nearest step value
    if (updateValue % step > 0) {
      const steps = 1 / step
      updateValue = Number((Math.round(updateValue * steps) / steps).toFixed(2))
    }

    // apply min max
    if (inputTextValue < min) updateValue = min
    if (inputTextValue > max - subSliderDiff) updateValue = max - subSliderDiff
    if (inputTextValue < disableMin) updateValue = disableMin

    setTextEntryValue(updateValue.toString())
    setPrevTextEntryValue(updateValue.toString())

    if (updateValue !== localValue) {
      setLocalValue(updateValue)
    }

    setIsEditingText(false)
  } // END handleOnBlur

  const handleOnChangeSlider = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (disabled || lockSlider) return
    let updateValue = stripFormatting(event.target.value)
    if (updateValue < min) updateValue = min
    if (updateValue < disableMin) updateValue = disableMin
    if (updateValue > max - subSliderDiff) updateValue = max - subSliderDiff
    if (updateValue !== localValue) {
      setLocalValue(updateValue)
      setTextEntryValue(updateValue.toString())
      setPrevTextEntryValue(updateValue.toString())
    }
  }

  const handleOnChangeTextEntry = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    // if (disabled || lockSlider) return
    const inputTextValue = stripInvalidChars(event.target.value)
    if (inputTextValue !== textEntryValue) setTextEntryValue(inputTextValue)
  }

  const idOrGenerated = id ? id : 'id-' + label.toLowerCase().replace(/ /g, '-')

  return (
    <>
      <div css={styles.container}>
        <div css={styles.labelBlock}>
          <label css={styles.label} htmlFor={idOrGenerated}>
            {label}
          </label>
          {lockSlider && <div css={styles.lockIconDiv}></div>}
          <input
            data-lpignore={'true'}
            css={styles.textInput}
            id={idOrGenerated}
            value={
              isEditingText
                ? textEntryValue
                : formatValue(
                    textEntryValue,
                    symbol && symbolInput ? symbol : '',
                    symbolBefore,
                  )
            }
            {...{ min, max }}
            onChange={handleOnChangeTextEntry}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            disabled={disabled || lockSlider}
          />
          {!shadowedValue ? null : (
            <div css={styles.shadowedValue}>{shadowedValue}</div>
          )}
        </div>
        <div css={styles.sliderBlock}>
          <PercentageToolTip
            percent={toolTipPercent}
            display={toolTipPercent > 0}
          />

          {!multiSlider || lockSlider ? (
            <input
              css={partialSliderStyles}
              type="range"
              min={min}
              max={max}
              value={localValue}
              step={step}
              onChange={handleOnChangeSlider}
            />
          ) : (
            <MultiThumbSlider
              min={min}
              max={max}
              subSliderValue={subSliderValue}
              value={localValue}
              step={step}
              onChange={handleOnChangeSlider}
            />
          )}
          <div css={styles.sliderLabels}>
            <div css={styles.minLabel}>
              {`${symbol && symbolBefore ? symbol : ''}${min.toLocaleString()}${
                !symbol || symbolBefore ? '' : symbol
              }`}
            </div>
            <div css={styles.maxLabel}>
              {`${
                symbol && symbolBefore ? symbol : ''
              }${max?.toLocaleString()}${
                !symbol || symbolBefore ? '' : symbol
              }`}
            </div>
          </div>
        </div>
      </div>
      <div css={additionalInfo ? styles.showInfo : styles.hideInfo}>
        <InformationSection text={additionalInfo} />
      </div>
    </>
  )
}
