import { css } from '@emotion/react'
import { cssTyp, mfThemeVar } from '@mulliganfunding/fe-style'
import { defFont } from './../../utils'
import radioButtonDefaultIcon from './../../assets/imgs/radio-button-default-icon.svg'
import radioButtonSelectedIcon from './../../assets/imgs/radio-button-selected-icon.svg'

export const bodyContentWrapper = css`
  label: body-content-wrapper;
  display: flex;
  padding: 40px 0 0 0 0;
  flex-direction: column;
  align-items: center;
`
export const offerTitle = css(
  'label: offer-title;',
  defFont('m', 32, null, null, mfThemeVar.TEXT_DEFAULT),
  'margin-top: 44px;',
)

export const offerText = css(
  `label: offer-text;`,
  defFont('r', 16, 24, null, mfThemeVar.TEXT_LIGHT),
  `text-align: center;
  margin-top: 27px;
  margin-bottom: 70px;`,
)

export const goBackSection = css`
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
`

export const goBackLink = css(
  'label: go-back-link',
  defFont('m', 16, null, null, mfThemeVar.LINK),
)

export const goBackChevronIcon = css`
  margin-right: 10px;
`

export const width750px = css`
  label: width-750-px;
  width: 750px;
`

const _shadedBlock = `
  background-color: var(${mfThemeVar.NEUTRAL_25});
`

export const sectionTitle = css(
  'label: section-title',
  defFont('m', 20, null, -0.3, mfThemeVar.TEXT_DEFAULT),
)

export const shadedIsoContactEmailBlock = css`
  ${_shadedBlock}
  padding: 22px;
  margin-top: 22px;
  margin-bottom: 50px;
`

const _heavyText16 = defFont('sb', 16, 24, 0.3, mfThemeVar.TEXT_DEFAULT).styles
const _lightText14 = defFont('r', 14, 20, 0.3).styles

export const shadedGuarantorBlock = css`
  ${_shadedBlock}
  padding: 40px 33px;
  margin-top: 22px;
  margin-bottom: 50px;
  box-sizing: border-box;
`

export const authSigText = css`
  label: auth-sig-text;
  ${_heavyText16}
`
export const missingEmailText = css`
  label: missing-email-text;
  padding: 10px 0px 10px 0px;
  ${_heavyText16}
`
export const reviewText = css`
  ${_lightText14}
  color: var(${mfThemeVar.TERTIARY});
  margin-top: 8px;
`

export const isoContactEmail = css`
  label: iso-contact-email;
  ${_heavyText16}
  text-align:center;
  width: 100%;
`

// DARK BOX ELEMENTS
export const darkBox = css`
  background-color: var(${mfThemeVar.TERTIARY});
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const darkBoxText = css(
  'label: dark-box-text;',
  defFont('sb', 16, 24, 0.3, mfThemeVar.TEXT_DEFAULT_ON_DARK),
  `margin-top:35px;`,
)

export const darkBoxFinePrint = css(
  `label: dark-box-fine-print;`,
  defFont('r', 14, 20, 0.3, mfThemeVar.TEXT_DEFAULT_ON_DARK),
  `
  text-align: center;
  width: 650px;
  margin: 30px 0;
  `,
)

export const radioRow = css`
  margin-top: 10px;
  margin-bottom: 26px;
`

export const radioLabel = css`
  label: radio-label;
  flex: 3;
  height: 24px;
  width: 199px;
  color: var(${mfThemeVar.TEXT_DEFAULT_ON_DARK});
  ${cssTyp._BODY}
  margin: 5px;
`

export const radioValue = css`
  label: radio-value;
  color: var(--shade-blue);
  text-align: right;
  height: 24px;
`

export const radioButton = css`
  label: radio-button;
  margin: 5px;
  margin-left: 0px;
  margin-left: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border-width: 0;
  vertical-align: middle;
  :after {
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    position: relative;
    content: '';
    display: inline-block;
    visibility: visible;
    background: url(${radioButtonDefaultIcon}) center no-repeat scroll;
  }
  :checked:after {
    width: 24px;
    height: 24px;
    top: 0;
    left: 0;
    position: relative;
    display: inline-block;
    visibility: visible;
    background: url(${radioButtonSelectedIcon}) center no-repeat scroll;
  }
`
