import { Card, Page } from './../../components'
import React from 'react'
import { iconError, iconLoanAgreement, iconOfferLocked } from './../../assets'
import * as styles from './message-page.style'
import { css } from '@emotion/react'

export enum MessagePageIconType {
  LOCKED_MESSAGE = 'LOCKED_MESSAGE',
  CREATED_MESSAGE = 'CREATED_MESSAGE',
  ERROR_MESSAGE = 'ERROR_MESSAGE',
}

type MessagePageTemplateProps = {
  widthPx?: number
  icon: string
  title: string
  children: React.ReactNode
}

export const MessagePageTemplate: React.FC<MessagePageTemplateProps> = ({
  widthPx,
  icon,
  title,
  children,
}) => {
  return (
    <Page title={'Offer Submission Calculator'}>
      <Card width={widthPx ? widthPx + 'px' : '1140px'}>
        <div css={styles.wrapper}>
          <img
            src={iconLoanAgreement}
            css={css`
              margin-top: 35px;
            `}
          />
          <div css={styles.title}>{title}</div>
          {children}
        </div>
      </Card>
    </Page>
  )
}

export enum MessagePageType {
  LOAN_AGREEMENT_CREATED = 'LOAN_AGREEMENT_CREATED',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_LONG',
  OFFER_LOCKED = 'OFFER_LOCKED',
}

type MessagePage = {
  type: MessagePageType
  children?: React.ReactNode
}

export const MessagePage: React.FC<MessagePage> = ({ type, children }) => {
  let title, icon, text
  if (type === MessagePageType.LOAN_AGREEMENT_CREATED) {
    title = 'Loan Agreement Created'
    icon = iconLoanAgreement
    text = (
      <div css={styles.text}>
        Thank You! The loan agreement has been created. Please check your email
        for further details, and please let us know if you have any questions.
        You can reach our team at <span css={styles.link}>(855) 433-8617</span>{' '}
        or send an email to{' '}
        <span css={styles.link}>partner@mulliganfunding.com</span>
      </div>
    )
  }
  if (type === MessagePageType.OFFER_LOCKED) {
    title = 'Offer Locked'
    icon = iconOfferLocked
    text = (
      <div css={styles.text}>
        The offer you're trying to access is now expired or locked. If you need
        assistance, please contact us at{' '}
        <span css={styles.link}>(855) 433-8617</span> or send an email to{' '}
        <span css={styles.link}>partner@mulliganfunding.com</span>.
      </div>
    )
  }
  if (type === MessagePageType.SOMETHING_WENT_WRONG) {
    title = 'Something Went Wrong'
    icon = iconError
    text = (
      <div css={styles.text}>
        We’re sorry, but the page you are trying to reach is not available.
        Please go back and try again. If this issue persists, please contact us
        at <span css={styles.link}>(855) 433-8617</span> or send an email to{' '}
        <span css={styles.link}>partner@mulliganfunding.com</span>.
      </div>
    )
  }

  return (
    <MessagePageTemplate icon={icon} title={title}>
      {text}
      {children}
    </MessagePageTemplate>
  )
}
