import { css } from '@emotion/react'

export const FooterNoteNode = (
  <div>
    <div css={css(`label: footer-note; padding: 5px;`)}>
      <sup>1</sup>This is a conditional offer. Mulligan Funding, as agent for
      FinWise Bank, reserves the right to require additional documentation for
      all applicants, including the borrowers' signed loan agreement, driver’s
      license, voided check, and bank verification.
    </div>

    <div css={css(`label: footer-note; padding: 5px;`)}>
      The majority of Mulligan Funding, LLC business loans are issued by Finwise
      Bank, a Utah state-chartered bank. Member FDIC. Certain loans are made by
      Mulligan Loans, LLC (a subsidiary of Mulligan Finding, LLC) who holds a
      California Finance Lenders License.
    </div>
  </div>
)
