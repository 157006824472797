import {
  Card,
  ErrorModal,
  InputOrDisplayBox,
  Page,
  SubmitButton,
} from './../../components'
import React, { useEffect, useState } from 'react'
import * as styles from './create-loan-agreement.styles'
import leftChevronIcon from './../../assets/imgs/left-chevron.svg'
import { isPageLoadingState, pendingOfferState } from './../../state'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { hasOwn, isEmailValid, logColor, scrollToTop } from './../../utils'
import {
  Guarantor,
  Guarantors,
  GuarantorsPost,
  InputMode,
  PendingOfferStatus,
  POST_HEADERS_JSON,
} from './../../constants'
import { Signee } from './signee'
import { css } from '@emotion/react'

export const CreateLoanAgreement: React.FC = () => {
  const setIsLoading = useSetRecoilState(isPageLoadingState)

  const [pendingOffer, setPendingOffer] = useRecoilState(pendingOfferState)
  const [guarantorsList, setGuarantorsList] = useState<Guarantor[]>([])
  const [isReviewLoanAgreement, setIsReviewLoanAgreement] =
    useState<boolean>(false)
  const [updatedGuarantorsObj, setUpdatedGuarantorsObj] = useState<Guarantors>(
    {},
  )
  const [isSubmitPressed, setIsSubmitPressed] = useState<boolean>(false)
  const [isDisplayErrorModal, setIsDisplayErrorModal] = useState<boolean>(false)
  const [isoEmail, setIsoEmail] = useState<string>('')
  useEffect(() => {
    scrollToTop()
  }, [])

  // load guarantors from pendingoffer into list
  useEffect(() => {
    if (pendingOffer?.guarantors) {
      const g = pendingOffer.guarantors
      const keys = Object.keys(g)
      const list = keys.map((key) => g[key])
      setGuarantorsList(list)
      const guarantorsObj = {}
      keys.forEach((key) => {
        const g = { ...pendingOffer.guarantors[key] }
        // originally we were only going to send guarantor_email for those that were updated.
        // but the back end is errorring if a guarantor does not have a guarantor_email, so...
        // if (g.guarantor_email) delete g.guarantor_email
        guarantorsObj[key] = g
      })
      setUpdatedGuarantorsObj(guarantorsObj)
    }
  }, [pendingOffer])

  const handleBack = async () => {
    setIsLoading(true)
    const status = PendingOfferStatus[PendingOfferStatus.PROVISIONAL_ACCEPTED]
    const response = await fetch(`/api/partner-portal/calculator/status`, {
      method: 'POST',
      headers: POST_HEADERS_JSON,
      body: JSON.stringify({ status, uuid: pendingOffer.uuid }),
    })
    if (response.ok) {
      // go back, set status to active, accepted
      setPendingOffer({ ...pendingOffer, status })
    } else {
      // error response
      logColor.red(
        'ERROR offer status change to provisional_accepted returned an error',
        response,
      )
      setIsDisplayErrorModal(true)
    }
    setIsLoading(false)
  }

  const areEmailsValid = () => {
    const keys = Object.keys(updatedGuarantorsObj)
    const invalidEmails = []
    keys.forEach((key) => {
      const guarantor = updatedGuarantorsObj[key]
      if (!hasOwn(guarantor, 'guarantor_email')) {
        return // do not test email for guarantor that did not require email entry
      }
      const isValidEmail = isEmailValid(guarantor.guarantor_email)
      if (!isValidEmail) {
        invalidEmails.push(guarantor.guarantor_email)
      }
    })

    if (!pendingOffer?.partner_email) {
      const isValidIsoEmail = isEmailValid(isoEmail)
      if (!isoEmail || !isValidIsoEmail) {
        invalidEmails.push(isoEmail)
      }
    }

    logColor.yellow(
      `Invalid email list: (${invalidEmails.length}): ${invalidEmails}`,
    )
    // TODO make invalid emails highlighted when tabbing through form
    return invalidEmails.length === 0
  }

  const handleSubmit = async () => {
    setIsSubmitPressed(true)
    if (!areEmailsValid()) {
      logColor.red('INVALID EMAILS', updatedGuarantorsObj)
      return
    }
    logColor.green('Guarantors', updatedGuarantorsObj)
    logColor.green('isReviewLoanAgreement', isReviewLoanAgreement)
    const postBodyObj: GuarantorsPost = {
      uuid: pendingOffer.uuid,
      iso_loan_agree_review_needed: isReviewLoanAgreement, // review loan agreement checkbox value here
      guarantors: updatedGuarantorsObj,
    }

    if (isoEmail.length > 0) postBodyObj.new_partner_email = isoEmail

    console.log('deflee postBodyObj: ', postBodyObj)
    logColor.purple('sending gaurantors to back end', { postBodyObj })
    setIsLoading(true)
    const response = await fetch(`/api/partner-portal/calculator/agreement`, {
      method: 'POST',
      headers: POST_HEADERS_JSON,
      body: JSON.stringify(postBodyObj),
    })
    logColor.gray('response', response)
    if (
      response?.status !== 200 ||
      (hasOwn(response, 'status') &&
        hasOwn(response, 'error') &&
        !hasOwn(response, 'owner'))
    ) {
      // error response
      // TODO if this offer is completed then this would fail
      logColor.red(
        'ERROR offer status change to provisional_accepted returned an error, TODO handle',
        response,
      )
      setIsDisplayErrorModal(true)
    } else {
      // set status to active, accepted
      const status = PendingOfferStatus[PendingOfferStatus.COMPLETED]
      setPendingOffer({ ...pendingOffer, status })
    }

    setIsLoading(false)
  } // END handleSubmit

  const handleReviewAgreementChange = (event) => {
    setIsReviewLoanAgreement(event.target.value === 'yes')
  }

  const handleSigneeEmailUpdate = (
    guarantorContactId: string,
    email: string,
  ) => {
    // update the email in the updatedGuarantorsObject
    setUpdatedGuarantorsObj({
      ...updatedGuarantorsObj,
      [guarantorContactId]: {
        ...updatedGuarantorsObj[guarantorContactId],
        guarantor_email: email,
      },
    })
  }

  const handleIsoEmailChange = (changeEvent) => {
    setIsoEmail(changeEvent.target.value)
  }

  return (
    <>
      <ErrorModal
        isDisplay={isDisplayErrorModal}
        handleClose={() => setIsDisplayErrorModal(false)}
      />
      <Page title={'Offer Submission Calculator'}>
        <div css={styles.goBackSection} onClick={handleBack}>
          <img
            css={styles.goBackChevronIcon}
            src={leftChevronIcon}
            alt={'go back icon'}
          />
          <div css={styles.goBackLink}> Back</div>
        </div>
        <Card width="1140px">
          <div css={styles.bodyContentWrapper}>
            <div css={styles.offerTitle}>Create Loan Agreement</div>
            <div css={styles.offerText}>
              Please add the details for your DocuSign Loan Agreement below.
            </div>
            <div css={styles.width750px}>
              <div css={styles.sectionTitle}>ISO Contact Email</div>
              <div css={styles.shadedIsoContactEmailBlock}>
                {!pendingOffer?.partner_email && (
                  <div css={styles.missingEmailText}>
                    Please enter an email.
                  </div>
                )}
                <InputOrDisplayBox
                  mode={
                    pendingOffer?.partner_email
                      ? InputMode.display_bold
                      : InputMode.input
                  }
                  value={pendingOffer?.partner_email || isoEmail}
                  onChange={handleIsoEmailChange}
                  isSubmitPressed={isSubmitPressed}
                />
              </div>
              <div css={styles.sectionTitle}>Guarantor Information</div>
              <div css={styles.shadedGuarantorBlock}>
                <div css={styles.authSigText}>Authorized Signee</div>
                <div css={styles.reviewText}>
                  Review or enter the email address of the authorized signer
                  below
                </div>
                {guarantorsList.map((guarantor, idx) => (
                  <Signee
                    key={'signee-' + idx}
                    guarantor={guarantor}
                    onChange={handleSigneeEmailUpdate}
                    isSubmitPressed={isSubmitPressed}
                  />
                ))}
              </div>
              <div css={styles.darkBox}>
                <div css={styles.darkBoxText}>
                  Would you like to review the loan agreement prior to sending
                  to the merchant?
                </div>
                <div css={styles.radioRow}>
                  <input
                    name={'reviewAgreement'}
                    css={styles.radioButton}
                    value={'yes'}
                    type={'radio'}
                    checked={isReviewLoanAgreement}
                    onChange={handleReviewAgreementChange}
                  />
                  <label css={styles.radioLabel}>Yes</label>
                  <input
                    name={'reviewAgreement'}
                    css={css(styles.radioButton, 'margin-left:19px')}
                    value={'no'}
                    checked={!isReviewLoanAgreement}
                    type={'radio'}
                    onChange={handleReviewAgreementChange}
                  />
                  <label css={styles.radioLabel}>No</label>
                </div>
                <SubmitButton onClick={handleSubmit} paddingLR={50} width={298}>
                  Request Loan Agreement
                </SubmitButton>
                <div css={styles.darkBoxFinePrint}>
                  Please note that the ISO sales team at Mulligan Funding will
                  be included in the email correspondence and have access to any
                  loan agreements generated in this manner.
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Page>
    </>
  )
}
