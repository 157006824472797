/* CSS Document */

import { css } from '@emotion/react'
import { cssColor } from '@mulliganfunding/fe-style'
import orangeBar from './../../assets/imgs/pattern-orangebar.svg'

export const container = css`
  label: container;
  display: flex;
  padding-bottom: 15px;

  input[type=range]: {
    webkit-appearance: none;
    background-color: transparent;
  }

  input[type=range]:focus: {
    outline: none;
  }
  ,
    input[type=text]: {
  }
`
export const slider = css`
  label: slider;
  position: relative;
  width: 100%;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
`

export const sliderDefaultTrack = css`
  label: slider-default-track;
  background-color: var(--slider-grey);
  z-index: 0;
  width: 100%;
  height: 6px;
  position: absolute;
`

const thumb = css`
  label: thumb;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
`
export const mainRangeSlider = (sliderPercentage) => {
  return css`
    label: main-range-slider;
    z-index: 4;
    ${thumb}
    ::-webkit-slider-thumb {
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;

      cursor: pointer;
      pointer-events: all;
      height: 39px;
      width: 17.62px;
      border: 2px solid white;
      border-radius: 10px;
      background-color: ${cssColor.TERTIARY};
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
      margin-top: -14px;
    }

    ::-moz-range-thumb {
      cursor: pointer;
      pointer-events: all;
      height: 39px;
      width: 17.62px;
      border: 2px solid white;
      border-radius: 10px;
      background-color: ${cssColor.TERTIARY};
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
    }

    ::-webkit-slider-runnable-track {
      background: linear-gradient(
        to right,
        var(--slider-blue) ${sliderPercentage}%,
        transparent ${sliderPercentage}% 100%
      );
      height: 6px;
    }
    ::-moz-range-progress {
      background-color: var(--slider-blue);
      height: 6px;
    }
  `
}
export const subRangeSlider = (sliderPercentage) => {
  return css`
    label: sub-range-slider;
    z-index: 3;
    ${thumb}

    ::-webkit-slider-thumb {
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;

      background-color: ${cssColor.PRIMARY};
      pointer-events: all;
      position: relative;

      cursor: pointer;
      height: 20px;
      width: 9px;
      border: 2px solid white;
      border-radius: 10px;
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
      margin-top: -7px;
    }

    ::-moz-range-thumb {
      background-color: ${cssColor.PRIMARY};
      pointer-events: all;
      position: relative;

      cursor: pointer;
      height: 15px;
      width: 5px;
      border: 2px solid white;
      borderradius: 10px;
      boxshadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
    }

    ::-webkit-slider-runnable-track {
      background: linear-gradient(
          to right,
          transparent ${sliderPercentage}%,
          var(--slider-grey) ${sliderPercentage}% 100%
        ),
        url(${orangeBar});
      height: 6px;
    }

    ::-moz-range-progress {
      background: url(${orangeBar});
      height: 6px;
    }
  `
}
